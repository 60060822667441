import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { FaHome } from "react-icons/fa";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { TfiGallery } from "react-icons/tfi";
import { TiThMenu } from "react-icons/ti";
import Drawer from '@mui/material/Drawer';
import work from '../services.json'
import Footer from './Footer';

const Services = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };

  return (
    <>
    {isTabletOrMobile ? (
        <>
        <div className='nav-mob-gallery'>
        <button className='nav-btn' onClick={toggleDrawer(true)}><TiThMenu /></button>
      <Drawer className='drawer'  open={open} onClose={toggleDrawer(false)}>
        <img src="/assets/logo-drawer.png" alt="logo" />
      <a href="/"><FaHome className='drawer-icon' /> Home</a>
      <a href="/services"><FaScrewdriverWrench /> Services</a>
      <a href="/gallery"><TfiGallery className='mail-icon'/> Gallery</a>
      </Drawer>
      <img src="/assets/logo.png" alt="logo" />
      </div>
      <div className='services services-page'>
        <h2>Our Services</h2>
        <div className='service-line service-page-line'></div>
        <p>"GPD Property Maintenance Services" is a company that offers services such as structural
          repairs, plastering, tiling, painting and decorating, floor levelling and roofing services.
        </p>
       </div>
       <div className='work-group'>
        {work.services.map((service)=>(
            <div className='work'>
                <h2>{service.name}</h2>
                <div className='work-line'></div>
                <p>{service.information}</p>
            </div>
        ))}
       </div>
       <Footer/>
    </>
    ): (
   <>
   <div className='nav-gallery'>
   <div className='logo-group'>
   <img src="/assets/logo.png" alt="logo" />
   <h2> GPD Property Maintenance Services</h2>
   </div>
   <div className='gallery-links'>
   <a href="/">Home</a>
   <a href="/services">Services</a>
   <a href="/gallery">Gallery</a>
   <a className='tel' href="tel:+447309099299">Call: +44 7309 099299</a>
   </div>
   </div>
   <div className='services services-page'>
        <h2>Our Services</h2>
        <div className='service-line service-page-line'></div>
        <p className='desktop-p'>GPD Property Maintenance Services  is a company that offers services such as structural
          repairs, plastering, tiling, painting and decorating, floor levelling and roofing services.
        </p>
       </div>
    <div className='works-container'>
     {work.services.map((work)=>(
      <>
      <div id='service'>
        <div id='service-text'>
        <h3>{work.name}</h3>
        <p>{work.information}</p>
        </div>
      
      <img id='service-img' src={work.url} alt="work" />
      
      </div>
      
      </>
     ))}
    </div>
    <Footer/>
</>
    )}
    </>
  )
}

export default Services