import React from 'react'
import { TiThMenu } from "react-icons/ti";
import { useMediaQuery } from 'react-responsive'
import Drawer from '@mui/material/Drawer';
import images from '../services.json'
import { FaHome } from "react-icons/fa";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { TfiGallery } from "react-icons/tfi";
import { FaWhatsapp } from 'react-icons/fa';
import { FaTiktok } from "react-icons/fa";
import { CiMail } from "react-icons/ci";


const Navbar = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };
  
    
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <>
    {isTabletOrMobile ? ( 
        <>
        <div className='header'>
      <div>
      <button className='nav-btn' onClick={toggleDrawer(true)}><TiThMenu /></button>
      <Drawer className='drawer'  open={open} onClose={toggleDrawer(false)}>
        <img src="/assets/logo-drawer.png" alt="logo" />
      <a href="/"><FaHome className='drawer-icon' /> Home</a>
      <a href="/services"><FaScrewdriverWrench /> Services</a>
      <a href="/gallery"><TfiGallery /> Gallery</a>
      
      </Drawer>
     </div>  
     <h1> <span>GPD</span>  Property Services</h1>
    </div>

     <div className='services-home'>
        <a href="/services">Our Services</a>
    </div>
     <div className='home-heading'>
       <em>Property Maintenance Services in London</em>
      </div>
      <p className='description'>
      With a team of skilled craftsmen and years of experience in the industry,
      from small touch-ups to full-scale renovations, we pride 
      ourselves on providing top-quality workmanship and unparalleled 
      customer service, ensuring that your vision for your property
       becomes a reality. Trust <em>GPD Property Services</em>  to bring out the best in your space,
       enhancing its beauty and functionality for years to come.
      </p>
      <div className='services'>
        <h2>What We Do</h2>
        <div className='service-line'></div>
        <p>"GPD Property Maintenance Services" is a company that offers services such as structural
          repairs, plastering, tiling, painting and decorating, floor levelling and roofing services.
        </p>
        <div className='card-group-mobile'>
         {images.services.map((service)=>(
         <div className='card-mobile'>
         <img src={service.url} alt="image" />
         <span className='card-name-mobile' >{service.name}</span>
         </div>
         ))}
        </div>
      </div>
        </>
    
     ):( 
     <div>
        <div className='desktop-navbar'>
          <div className='nav-group'>
            <a href="/"><img src="/assets/logo.png" alt="logo" /></a>
            <div className='links'>
             <a href="/">Home</a>
             <a href="/services">Services</a>
             <a href="/gallery">Gallery</a>
             <a className='tel' href="tel:+447309099299"> +44 7309 099299</a>
            </div>
          </div>
          <div>
          <h1 className='hero-title'>GPD Property Maintenance Service</h1>
           <p className='hero-paragraph'>With a comprehensive suite of services, we distinguish ourselves by consistently delivering projects within designated timelines and financial parameters.</p>
           <a className='hero-btn' href="/services">Our Services</a>
          </div>
         
         </div>
        <div className='main-section'>
        <div className='home-heading-desktop'>
       <em>Property Maintenance Services in London</em>
      </div>
      <div className='line'></div>
      <p className='description-desktop'>
      With a team of skilled craftsmen and years of experience in the industry,
      from small touch-ups to full-scale renovations, we pride 
      ourselves on providing top-quality workmanship and unparalleled 
      customer service, ensuring that your vision for your property
       becomes a reality. Trust <em>GPD Property Services</em>  to bring out the best in your space,
       enhancing its beauty and functionality for years to come.
      </p>
        </div>
      <div className='services-desktop'>
       <h2>What We Do</h2>
       <div className='service-line'></div>
       <p className='service-description'>
       ""GPD Property Maintenance Services" is your trusted partner for
        a wide array of home improvement needs. With 
        our expertise in structural repairs, plastering, 
        tiling, painting and decorating, floor leveling, 
        and roofing services, we ensure that every aspect 
        of your property receives the attention it deserves.
         Our dedicated team of professionals is committed to
          delivering high-quality workmanship and exceptional 
          customer service, ensuring your satisfaction with 
          every project. Whether you're looking to enhance the
          aesthetic appeal of your interiors or strengthen the
           structural integrity of your property, you can rely on us to 
           provide reliable solutions tailored to your unique requirements.
       </p>
     <div className='card-group'>
     {images.services.map((service)=>(
        <div className='card'>
          <img src={service.url} alt="image" />
          <span className='card-name'>{service.name}</span>
        </div>
     ))}
     </div>
      </div>
       
     </div>
     )}
    </>
  )
}

export default Navbar