import React from 'react'
import { TiSocialInstagram } from "react-icons/ti";
import { FaTiktok } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
<footer>
  <div className='footer-left'>
<img src="/assets/logo.png" alt="logo" />
<p>GPD Property Services </p>
<span>Your Trusted Partner</span>
  </div>
  <div className='footer-right'>
    <h2>Social Media</h2>
    <div className='social-logo'>
    <a href="https://www.instagram.com/gpd_property/?hl=en"><TiSocialInstagram /></a>
    <a href="https://www.tiktok.com/@gpd.property"><FaTiktok /></a>
    <a href="https://wa.me/447309099299?text=Hello%20there%21%20I%20have%20a%20question%20about%20your%20services.%20Could%20you%20please%20provide%20more%20information%3F"><FaWhatsapp /></a>
    </div>
    </div>
</footer>
  )
}

export default Footer


