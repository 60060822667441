import Home from './components/Home';
import './App.css';
import Gallery from './components/Gallery';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from './components/Services';
function App() {
  return (
    <div className="App">
 <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
        </Route>
        <Route path='/gallery' element={<Gallery/>} />
        <Route path='/services' element={<Services/>}/>
  
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
