import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import '../../src/App.css'
const Home = () => {
  return (
    <>
      <Navbar/>
      <Footer/>
    </>
 
  )
}

export default Home