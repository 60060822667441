import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { FaHome } from "react-icons/fa";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { TfiGallery } from "react-icons/tfi";
import { TiThMenu } from "react-icons/ti";
import Drawer from '@mui/material/Drawer';
import images from '../services.json'
import Footer from './Footer';

const Gallery = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
      setOpen(newOpen);
    };


    return (
        <>
            {isTabletOrMobile ? (
                <>
                <div className='nav-mob-gallery'>
                  <button className='nav-btn' onClick={toggleDrawer(true)}><TiThMenu /></button>
      <Drawer className='drawer'  open={open} onClose={toggleDrawer(false)}>
        <img src="/assets/logo-drawer.png" alt="logo" />
      <a href="/"><FaHome className='drawer-icon' /> Home</a>
      <a href="/services"><FaScrewdriverWrench /> Services</a>
      <a href="/gallery"><TfiGallery /> Gallery</a>
      </Drawer>
      <img src="/assets/logo.png" alt="logo" />
      </div>
      <h1 className='gallery-heading'>Gallery</h1>
      <div>
       {images.gallery.map((image)=>(
        <div key={image.id} className='gallery-container-mobile'>
            <h2>{image.name}</h2>
            <img src={image.url} alt="image" />
            <img src={image.url2} alt="image" />
            <img src={image.url3} alt="image" />
            <img src={image.url4} alt="image" />
            <div className='line-gallery'></div>
        </div>
       ))}
      </div>
      <Footer/>
      </>
            ) : (
                <div>
                <div className='nav-gallery'>
                    <div className='logo-group'>
                    <img src="/assets/logo.png" alt="logo" />
                    <h2> GPD Property Maintenance Services</h2>
                    </div>
                  
                 <div className='gallery-links'>
                  <a href="/">Home</a>
                  <a href="/Services">Services</a>
                  <a href="/Gallery">Gallery</a>
                  <a className='tel' href="tel:+447309099299">Call: +44 7309 099299</a>
                 </div>
                </div>

                <div className='desktop-gallery-group' >
                {images.gallery.map((image)=>(
        <div key={image.id} >
            <h2> <img src={image.doodle} alt="doodle" /> {image.name}</h2>
            <div className='gallery-img'>
            <img src={image.url} alt="image" />
            <img src={image.url2} alt="image" />
            <img src={image.url3} alt="image" />
            <img src={image.url4} alt="image" />
            </div>
            <div className='line'></div>
        </div>
       ))}
                </div>
                <Footer/>
                </div>
            )}
           
        </>
    )
}

export default Gallery